import { FunctionComponent, useContext, useEffect, useState } from "react";
import FeedbackHistory from "./Components/FeedbackHistory";
import { useMsal } from "@azure/msal-react";
import ApiHelper from "../Shared/ApiHelper";
import { UserProfileContext } from "../Hooks/useProfile";
import { FeedbackEntity } from "../Shared/Models/FeedbackEntity";

const FeedbackPage: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());
    const [feedbackHistory, setFeedbackHistory] = useState<FeedbackEntity[]>([]);

    useEffect(() => {
        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/Feedback?id=${userProfile?.AppUser?.entity?.id}`,
            "GET"
        )
        .then(async (result: Response) => {
            var data: FeedbackEntity[] = await result.json();
            setFeedbackHistory(data);
        })
        .catch((reason) => console.error("Could not retrieve feedback history", reason))
    }, [userProfile, api])

    return (
        <div className="content-page">
            <FeedbackHistory histories={feedbackHistory} />
         </div>
    )
}

export default FeedbackPage;