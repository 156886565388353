import { FunctionComponent, useContext, useState } from "react";
import { toast } from "react-toastify";
import { FeedbackFormInputs } from '../Shared/Models/FeedbackFormInputs';

import ApiHelper from "../Shared/ApiHelper";
import { ToastConfig } from "../Shared/Models/ToastConfig";
import { UserProfileContext } from "../Hooks/useProfile";
import { useMsal } from "@azure/msal-react";
import { IdentifierType } from "../Shared/Models/Identifier";
import FeedbackForm from "./Components/FeedbackForm";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const CreateFeedbackPage: FunctionComponent = () => {
    const userProfile = useContext(UserProfileContext);
    const navigate = useNavigate();
    const { instance } = useMsal();
    const [api] = useState(new ApiHelper());

    const handleOnSubmit: SubmitHandler<FeedbackFormInputs> = (data) => {
        const postObject = { ...data, feedbackType: parseInt(data.feedbackType.toString()) }

        api.callApi(
            instance,
            [process.env.REACT_APP_B2C_SCOPE ?? ''],
            `${process.env.REACT_APP_CLIENTEX_APIBASE}/Feedback`,
            "POST",
            JSON.stringify(postObject)
        )
        .then(async (result: Response) => {
            if (result.status === 200) {
                toast.success("Feedback submitted.", ToastConfig)
            } else {
                toast.error("Feedback could not be submitted. Please try again", ToastConfig);
            }
        })
        .catch((reason) => console.error("Feedback submission rejected", reason))
    }
    
    return (
        <div className="content-page">
            <button className="btn btn-link" onClick={e => navigate(-1)}>Back to Feedback</button>
            <FeedbackForm 
                onSubmit={handleOnSubmit} 
                username={userProfile?.AppUser?.entity?.name}
                email={userProfile?.AppUser?.entity.identifiers.find(x => x.type === IdentifierType.Email)?.value}
                entityId={userProfile?.AppUser?.entity?.id}
            />
         </div>
    )
}

export default CreateFeedbackPage;